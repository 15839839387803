import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MetadataProvider } from '@app/logs/logs-metadata-query/metadata.provider';

@Component({
  selector: 'sh-deploy-package-metadata',
  templateUrl: './deploy-package-metadata.component.html',
  styleUrls: ['./deploy-package-metadata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeployPackageMetadataComponent {
  @Input() appNamesControl: AbstractControl;
  @Input() subNamesControl: AbstractControl;
  @Input() selectedVersionControl: AbstractControl;
  @Input() versions: string[];
  @Input() appendTo: string = null;
  appNames$ = this.metadataProvider.options.applicationMetadata$;
  subNames$ = this.metadataProvider.options.subsystemMetadata$;

  constructor(private metadataProvider: MetadataProvider) {}
}
