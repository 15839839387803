import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeployPackageMetadataComponent } from './deploy-package-metadata.component';
import { CgxSelectModule } from '@coralogix/design';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DeployPackageMetadataComponent],
  imports: [CommonModule, CgxSelectModule, ReactiveFormsModule],
  exports: [DeployPackageMetadataComponent],
})
export class DeployPackageMetadataModule {}
