<ng-container>
  <div *ngIf="appNamesControl" class="select-metadata">
    <div class="select-metadata-title">Applications</div>
    <cgx-select [appendTo]="appendTo" selectType="checkbox" [items]="appNames$ | async" [formControl]="appNamesControl"></cgx-select>
  </div>

  <div *ngIf="subNamesControl" class="select-metadata">
    <div class="select-metadata-title">Subsystems</div>
    <cgx-select [appendTo]="appendTo" selectType="checkbox" [items]="subNames$ | async" [formControl]="subNamesControl"></cgx-select>
  </div>

  <div *ngIf="selectedVersionControl && versions" class="select-metadata">
    <div class="select-metadata-title">Version</div>
    <cgx-select [items]="versions" [formControl]="selectedVersionControl" [appendTo]="appendTo"></cgx-select>
  </div>
</ng-container>
