import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl, FormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  public logFormValidationErrors(
    group: FormGroup,
    formErrors: { [key: string]: string },
    validationMessages: { [key: string]: { [key: string]: string } },
  ): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);

      if (abstractControl instanceof FormGroup) {
        this.logFormValidationErrors(abstractControl, formErrors, validationMessages);
      } else if (abstractControl instanceof FormArray) {
        this.logArrayValidationError(abstractControl, key, formErrors, validationMessages);
      } else {
        this.logControlValidationError(abstractControl, key, formErrors, validationMessages);
      }
    });
  }

  public logControlValidationError(
    control: AbstractControl,
    controlKey: string,
    formErrors: { [key: string]: string },
    validationMessages: { [key: string]: { [key: string]: string } },
  ): void {
    formErrors[controlKey] = '';
    if (control && !control.valid && control.status !== 'DISABLED') {
      const messages = validationMessages[controlKey];
      const firstErrorKey = Object.keys(control.errors)[0];
      formErrors[controlKey] = messages[firstErrorKey];
    }
  }

  public logArrayValidationError(
    control: FormArray,
    controlKey: string,
    formErrors: { [key: string]: string },
    validationMessages: { [key: string]: { [key: string]: string } },
  ): void {
    control.controls.forEach(e => {
      if (e instanceof FormGroup) {
        this.logFormValidationErrors(e, formErrors, validationMessages);
      }
    });
  }
}
